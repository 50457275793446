import React from 'react'
import {useNavigate} from 'react-router-dom'

const Submitted = () => {
  let navigate = useNavigate();
  return (
    <div className='submitted'>
        <h1>Your response was submitted!</h1>
        <button onClick={() => navigate('/')}>RETURN TO HOME</button>
    </div>
  )
}

export default Submitted