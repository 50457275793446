import React, {useState, useEffect} from 'react'
import Footer from '../components/Footer'

const AboutUs = () => {
    const [state, setState] = useState(false)
    const [active, setActive] = useState('c')
    const offerValues = [
      {
        title: 'COMMERCIAL',
        desc: 'Dawcon Construction has a wealth of experience in delivering top-quality commercial projects. From new construction to renovations and maintenance, our team has the skills and expertise to tackle a wide range of commercial buildings. We understand the unique needs and requirements of commercial projects and work closely with our clients to ensure their satisfaction.'
      }, 
      
      {
        title: 'RESIDENTIAL',
        desc: 'At Dawcon Construction, we specialize in delivering superior residential projects. Our team of experts work closely with homeowners to understand their unique needs and create custom solutions tailored to those needs. We pride ourselves on our ability to deliver high-quality homes and renovations that exceed expectations and ensure complete homeowner satisfaction.'
      }, 
      
      {
        title: 'DESIGN & BUILD',
        desc: 'Dawcon Construction is a leader in design and build services. Our team of experts is skilled in architectural design, project management, and construction execution. We work closely with our clients to understand their specific needs and requirements, creating custom solutions that are tailored to their unique needs. We pride ourselves on delivering outstanding results for our clients, every time.'
      }
    ]

    useEffect(() => {
		  setState(true)
	  }, [])

    useEffect(() => {
      window.scrollTo(0, 0)
    }, [])

    return (
      <div>
          <div className="aboutUsSection">
              <div className="aboutTitleContainer">
                  <h1 className={`welcomeTitle ${state ? "welcomeTitleShow" : ''}`}>ABOUT <span style={{color: 'orange'}}>DAWCON</span></h1>
              </div>
              <div className="aboutDescriptionContainer">
                <p className="aboutDescription">At Dawcon Construction, we are proud of the reputation we have established over the years. Since it's founding, Dawcon construction has been providing top-quality construction services to clients across a wide range of industries. Our commitment to excellence in both design and build is evident in every project we undertake, and we are dedicated to creating structures that are not only functional, but also visually stunning, leaving a lasting impression on all who experience them.<br/><br/>
                {/* We have a team of highly skilled and experienced professionals who are dedicated to delivering outstanding results for our clients. Our team members have a wealth of knowledge and expertise in the construction industry, and are committed to staying up-to-date with the latest industry trends and technologies. This allows us to provide our clients with the most efficient and cost-effective solutions for their construction needs.<br/><br/>

                At Dawcon Construction, we understand that every project is unique, and we work closely with our clients to understand their specific needs and requirements. We take the time to listen to our clients and work with them to create custom solutions that are tailored to their unique needs. From initial concept to final completion, we are committed to delivering the highest quality workmanship and customer service.<br/><br/>

                We take great pride in our ability to deliver projects on time and within budget. Our team is highly skilled in project management, and we have the resources and experience to handle projects of all sizes and complexity. We are committed to working closely with our clients throughout the entire construction process, ensuring that their expectations are met and exceeded.<br/><br/>

                In addition to our construction services, we also offer a wide range of consulting and design services. Our team of experts can help you with everything from architectural design to project management, and we are committed to providing our clients with the best possible solutions for their needs.<br/><br/>

                In conclusion, at Dawcon Construction, we strive for excellence in both design and build, creating structures that are not only functional, but also visually stunning, leaving a lasting impression on all who experience them. Our team of experienced professionals are dedicated to delivering outstanding results for our clients, and we take great pride in our ability to deliver projects on time and within budget. Whether you need a new building constructed or a renovation, we are here to help. Contact us today to learn more about how we can help you with your next construction project. */}</p>
                <div className="aboutDescriptionRight">
                  <h1 className='aboutDescriptionTitle'><span>DAWCON</span> CONSTRUCTION</h1>
                </div>
              </div>
              <div className="aboutServicesContainer">
                  <div className="aboutServicesLeft">
                    <h1 className='aboutDescriptionTitle'>WHAT WE OFFER</h1>
                    <div className="aboutServices">
                      <div style={{marginLeft: '0px'}} onClick={() => setActive('c')} className={`aboutService ${active === 'c' ? 'aboutServiceActive' : ''}`}>
                        <i className="fa-solid fa-helmet-safety"></i>
                        <h4 className="aboutServiceTitle">Commercial</h4>
                      </div>
                      <div onClick={() => setActive('r')} className={`aboutService ${active === 'r' ? 'aboutServiceActive' : ''}`}>
                        <i className="fa-solid fa-hammer"></i>
                        <h4 className="aboutServiceTitle">Residential</h4>
                      </div>
                      <div onClick={() => setActive('d')} className={`aboutService ${active === 'd' ? 'aboutServiceActive' : ''}`}>
                        <i className="fa-solid fa-pen-ruler"></i>
                        <h4 className="aboutServiceTitle">Design & Build</h4>
                      </div>
                    </div>
                  </div>
                  <div className={`aboutServiceOffer ${active === 'r' ? 'aboutServiceOfferR' : active === 'd' ? 'aboutServiceOfferD' : ''}`}>
                        <h1>{active === 'c' ? offerValues[0].title : active === 'r' ? offerValues[1].title : offerValues[2].title}</h1>
                        <p>{active === 'c' ? offerValues[0].desc : active === 'r' ? offerValues[1].desc : offerValues[2].desc}</p>
                        <div className="aboutServiceDots">
                          <div onClick={() => setActive('c')} className={`aboutServiceDot ${active === 'c' ? 'aboutServiceDotActive' : ''}`}></div>
                          <div onClick={() => setActive('r')} className={`aboutServiceDot ${active === 'r' ? 'aboutServiceDotActive' : ''}`}></div>
                          <div onClick={() => setActive('d')} className={`aboutServiceDot ${active === 'd' ? 'aboutServiceDotActive' : ''}`}></div>
                        </div>
                  </div>
              </div>
              <div className="aboutDescriptionContainer aboutVisionContainer">
                <div className="aboutDescriptionRight aboutDescriptionLeft">
                  <h1 className='aboutDescriptionTitle aboutVisionTitle'><span>OUR</span> VISION</h1>
                </div>
                <p className="aboutDescription">At Dawcon Construction, we are committed to building a better future for our clients, employees, and communities. We are constantly seeking new and innovative ways to improve our processes and technology, to minimize our environmental impact and to create a sustainable development. We strive to create not only functional but also beautiful and sustainable buildings. Our vision for the future is to continue to be a leader in the industry, delivering high-quality projects that exceed expectations, and to continue to be a responsible corporate citizen, dedicated to making a positive impact on the world around us.</p>
              </div>
          </div>
          <Footer footerColor='dark' />
      </div>
    )
}

export default AboutUs