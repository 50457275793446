import React, {useEffect, useState} from 'react'
import { Link } from 'react-router-dom'

const Welcome = () => {
	const [state, setState] = useState(false)
  useEffect(() => {
		setState(true)
	}, [])
  return (
    <div className='welcome'>
			<div className='welcomeContainer'>
				<h1 className={`welcomeTitle ${state ? "welcomeTitleShow" : ''}`}><span style={{color: 'orange'}}>DAWCON</span> CONSTRUCTION</h1>
				<p className='welcomeDescription'>Ottawa's most dependable construction company</p>
				<Link to='/contact'><button className='welcomeBtn'>GET A QUOTE</button></Link>
			</div>
    </div>
  )
}

export default Welcome