import React, {useEffect, useState} from 'react'
import { useNavigate } from 'react-router-dom';
import { send } from 'emailjs-com';
import Footer from '../components/Footer.js'

const Contact = () => {
		let navigate = useNavigate()

		const [toSend, setToSend] = useState({
				from_name: '',
				company: '',
				phone_number: "",
				message: '',
				reply_to: '',
		});

		const [button, setButton] = useState(false);

		useEffect(() => {
				if(toSend.from_name !== "" && toSend.message !== "" && toSend.reply_to !== "") {
						setButton(true);
				} else {
						setButton(false)
				}
				console.log(toSend)
		}, [toSend, setButton])

		useEffect(() => {
			window.scrollTo(0, 0)
		}, [])

		const onSubmit = (e) => {

				e.preventDefault();
				// if(toSend.phone_number === ""){
				//     setToSend({...toSend, phone_number: "NOT PROVIDED"})
				// }]
				setButton(false)
				if(toSend.from_name !== "" && toSend.message !== "" && toSend.reply_to !== "") {
								send(
										'service_op13dwf',
										'template_cmdeudf',
										toSend,
										'J0BL3mUhohNeQXatU'
								)
										.then((response) => {
												console.log('SUCCESS!', response.status, response.text);
										})
										.then(() => {
												setToSend({
														from_name: '',
														company: '',
														phone_number: "",
														message: '',
														reply_to: '',
												})
												navigate('/submitted')
										})
										.catch((err) => {
												console.log('FAILED...', err);
										});
				}
				setButton(false)
		};

		const handleChange = (e) => {
				setToSend({ ...toSend, [e.target.name]: e.target.value });
		};

		return (
			<div className='contact'>
					<div className='contactTitleContainer'>
						<h1 className='contactTitle'>CONTACT US</h1>
						<p className='contactDesc'> Are you looking for a general contractor?
							Whether you are commercial or residential, we at Dawcon are experienced and ready to help you with your construction needs.
						</p>
					</div>
					<div className="contactContainer">
							<div className="contactBox">
								<div className="contactBoxPhone">
									<div className='contactBoxPhoneRow'>
										<i className="fa-solid fa-phone"></i> <div><p>Call Us:</p><p className='contactLink'>(613)-600-9190</p></div>
									</div>
									<div className='contactBoxPhoneRow'>
										<i className="fa-solid fa-envelope"></i> <div><p>Email Us:</p><p className='contactLink'>info@dawcon.ca</p></div>
									</div>
									<div className='contactBoxPhoneRow'>
										<i className="fa-solid fa-clock"></i> <div><p>Office Hours:</p><p>9:00AM - 5:00PM</p></div>
									</div>
								</div>
							</div>
							<div className="contactFormContainer">
									<form className='contactPageForm' onSubmit={onSubmit}>
											<div className="contactTop">
												<div className="contactInput">
														<div className="contactFormInputTop contactInputTop">
																<p className="contactLabel">Name:</p>
																<p className='contactFormStar'>*</p>
														</div>
														<input name='from_name' value={toSend.from_name} onChange={handleChange} type="text" />
												</div>
												<div className="contactInput">
														<div className="contactFormInputTop contactInputTop">
																<p className="contactLabel">Email:</p>
																<p className='contactFormStar'>*</p>
														</div>
														<input name='reply_to' value={toSend.reply_to} onChange={handleChange} type="text" />
												</div>
											</div>
											<div className="contactBottom">
												<div className="contactInput">
														<div className="contactFormInputTop contactInputTop">
																<p className="contactLabel">Phone number:</p>
																<p className='contactStar'></p>
														</div>
														<input name='phone_number' value={toSend.phone_number} onChange={handleChange} type="text" />
												</div>
												<div className="contactInput">
														<div className="contactFormInputTop contactInputTop">
																<p className="contactLabel">Company:</p>
																<p className='contactFormStar'></p>
														</div>
														<input name='company' value={toSend.company} onChange={handleChange} type="text" />
												</div>
											</div>
											<div className="contactFormTextareaTop">
													<p className="contactLabel">Message:</p>
													<p className='contactFormStar'>*</p>
											</div>
											<textarea name='message' value={toSend.message} onChange={handleChange} className='contactTextarea'></textarea>
									</form>
							</div>
					</div>
					<div>
            			<button onClick={onSubmit} disabled={!button} className={`contactFormBtn ${button ? "contactFormBtnShow" : ""}`}>SUBMIT</button>
        			</div>
					<Footer footerColor='dark' />
			</div>
		)
}

export default Contact