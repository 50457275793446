import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { ContextProvider } from './Context';
import { BrowserRouter as Router } from 'react-router-dom';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyAdwapTkwgI3fqiBAj_b6ByiOUEkTZBxgk",
  authDomain: "dawcon-17c39.firebaseapp.com",
  projectId: "dawcon-17c39",
  storageBucket: "dawcon-17c39.appspot.com",
  messagingSenderId: "756255319788",
  appId: "1:756255319788:web:204d9dcc65280f547e27c9",
  measurementId: "G-59Y6G5GQ2G"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Router>
    <ContextProvider>
      <App />
    </ContextProvider>
  </Router>
);
