import React from 'react'
import {Link} from 'react-router-dom'

const ServicesPrev = () => {
  return (
    <div>
        <div className="dividerServices">
            <h1><span>Want a quote? </span><br />Contact us now!</h1>
            <div>
                <Link to='/contact'><button className='dividerServicesBtn'>CONTACT US</button></Link>
            </div>
        </div>
        <div className="servicesPrevSection">
            <h1 className='servicesPrevTitle'>SERVICES</h1>
            <div className="servicesPrev">
                <div className="servicePrev">
                    <i className="fa-solid fa-helmet-safety"></i>
                    <h3>COMMERCIAL</h3>
                    <p>Dawcon Construction has successfully served numerous commercial construction sites with thorough, dependable, and exact budgets and timeframes for years. We ensure a successful project from beginning to end with a solid and knowledgeable employee base.</p>
                </div>
                <div className="servicePrev">
                    <i className="fa-solid fa-hammer"></i>
                    <h3>RESIDENTIAL</h3>
                    <p>Dawcon Construction offers a wide range of services, from new home construction to remodeling and additions. We have a team of experienced professionals who are dedicated to providing quality workmanship and customer service.</p>
                </div>
                <div className="servicePrev servicePrevLast">
                    <i className="fa-solid fa-pen-ruler"></i>
                    <h3>DESIGN & BUILD</h3>
                    <p>At Dawcon Construction, we strive for excellence in both design and build, creating structures that are not only functional, but also visually stunning, leaving a lasting impression on all who experience them.</p>
                </div>
            </div>
            {/* <div className='servicePrevSeeMoreContainer'>
                <p className='servicePrevSeeMore'>SEE MORE<i className="fa-solid fa-arrow-right"></i></p>
            </div> */}
        </div>
    </div>
  )
}

export default ServicesPrev