import React, {useState, useEffect} from 'react'
import Footer from '../components/Footer'

const Photos = () => {
    const [list, setList] = useState([])
  
    useEffect(() => {
        for(let i = 1; i<= 37; i++) {
            setList(e => [...e, "img"+i])
        }
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

  return (
    <div className='photos'>
        <div className="photosContainer">
            {list.map(e => (
                <img className='photo' src={require(`../anas-photos/${e}.jpeg`)} alt="" />
            ))}    
        </div>
        <Footer footerColor='dark' />
    </div>
  )
}

export default Photos