import React, {useContext} from 'react'
import Logo from '../images/dark-text.svg'
import { Context } from '../Context'
import { Link } from 'react-router-dom';

const Navbar = () => {
  const {setShowSidebar, showSidebar} = useContext(Context);
  return (
    <div className={`navbar ${showSidebar ? "hideNavbar" : ""}`}>
        <Link to='/'><img className='navbarImg' src={Logo} alt="" /></Link>
        <div className="navbarRight">
            <Link to='/'><button className="btn navlink">HOME</button></Link>
            <Link to='/about-us'><button className="btn navlink">ABOUT US</button></Link>
            <Link to='/photos'><button className="btn navlink">GALLERY</button></Link>
            {/* <Link to='/services'><button className="btn navlink">SERVICES</button></Link> */}
            <Link to='/contact'><button className="btn navlink">CONTACT US</button></Link>
        </div>
        <div className="hamburger" onClick={() => setShowSidebar(true)}>
          <i className="fa-solid fa-bars"></i>
        </div>
    </div>
  )
}

export default Navbar