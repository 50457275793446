import React from 'react'
import SideImg from '../images/desc.jpg'

const Description = () => {
  return (
    <div className="descSection">
            <div className='descLeft'>
                <h1 className="descTitle">WELCOME TO DAWCON CONSTRUCTION INC.</h1>
                <p className="desc">Dawcon Construction is a renowned and established construction and renovation company based in Ottawa, Ontario. Our company vision is to become the customers' most preferred choice by attaining excellence in quality and timely completed value added projects. We at Dawcon provide the highest level of service in the construction industry while offering superior craftsmanship to every project we handle.</p>
                <div className='homeFacts'>
                    <div className="homeFact">
                        <div>
                            <i className="fa-solid fa-screwdriver-wrench"></i>
                            <h1>2019</h1>
                        </div>
                        <p>FOUNDED IN</p>
                    </div>
                    <div className="homeFact">
                        <div>
                            <i className="fa-solid fa-circle-check"></i>                        
                            <h1>100%</h1>
                        </div>
                        <p>SATISFACTION RATE</p>
                    </div>
                </div>
            </div>
            <div className="imgBar"></div>
            <img src={SideImg} className='descImg' alt="" />
        </div>
  )
}

export default Description