import React, {useContext, useEffect} from 'react'
import Logo from '../images/dark-text.svg'
import { Context } from '../Context'
import {Link} from 'react-router-dom'
import useWindowDimensions from '../hooks/getWindowDimensions';


const Sidebar = () => {
    const {showSidebar, setShowSidebar} = useContext(Context);
    const { width } = useWindowDimensions();

    useEffect(() => {
        if(width <= 850){
            setShowSidebar(false)
        }
    }, [width, setShowSidebar]) 
    // TO DO:
    // Change up the format. Instead of showing sidebar based on variable, set class based on variable that allows the position of the sidebar to come zooming in from the left, which means you must initially set the position of the sidebar to be to the left of the screen. As for the sidebar cover, use the variable format as below
    return (
        <div>
            {(width <= 850)  && 
            <div>
                <div className={`sidebar ${showSidebar && "showSidebar"}`}>
                    <div className='sidebarTop'>
                        <Link to='/'><img className='sidebarImg' src={Logo} alt="" /></Link>
                        <i className="fa-solid fa-xmark sidebarX" onClick={() => setShowSidebar(false)}></i>
                    </div>
                    <div className="sidebarBottom">
                        <Link to='/'><button onClick={() => setShowSidebar(false)} className="btn sidebarNavlink">HOME</button></Link>
                        <Link to='/about-us'><button onClick={() => setShowSidebar(false)} className="btn sidebarNavlink">ABOUT US</button></Link>
                        <Link to='/photos'><button onClick={() => setShowSidebar(false)} className="btn sidebarNavlink">GALLERY</button></Link>
                        {/* <Link to='/services'><button onClick={() => setShowSidebar(false)} className="btn sidebarNavlink">SERVICES</button></Link> */}
                        <Link to='/contact'><button onClick={() => setShowSidebar(false)} className="btn sidebarNavlink sidebarNavlinkLast">CONTACT US</button></Link>
                    </div>
                </div>
                {showSidebar && <div className="sidebarCover"></div>}
            </div>}
        </div>
        
    )
}

export default Sidebar