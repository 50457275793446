import './App.css';
import Header from './components/Header';
import Navbar from './components/Navbar';
import Sidebar from './components/Sidebar';
import Home from './screens/Home';
import {Routes, Route} from 'react-router-dom'
import Submitted from './screens/Submitted';
import Contact from './screens/Contact';
import Photos from './screens/Photos';
import AboutUs from './screens/AboutUs';

function App() {
  return (
    <>
    {/* TO DO: once submitting the contact form, bring user to a different page saying it was submitted with a button to take them home */}
      <Header />
      <Navbar />
      <Sidebar />
      <Routes>
        <Route exact path="/" element={<Home />}/>
        <Route path='/contact' element={<Contact />} />
        <Route path='/submitted' element={<Submitted />} />
        <Route path='/photos' element={<Photos />} />
        <Route path='/about-us' element={<AboutUs />} />
      </Routes>
    </>
  );
}

export default App;
