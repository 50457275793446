import React, { useEffect, useState } from 'react'
import Light from '../images/dark-text.svg'
import Dark from '../images/light-text.svg'
import { Link } from 'react-router-dom';

const Footer = ({footerColor}) => {
  const [img, setImg] = useState('');

  useEffect(() => {
      if(document.getElementById('footer').classList.contains('footerDark')) {
        setImg(Dark)
      } else {
        setImg(Light)
      }
  })

  return (
    <div id='footer' className={footerColor === "light" ? 'footerLight' : 'footerLight footerDark'}>
        <img className='navbarImg' src={img} alt="" />
        <div>
            <div>
                <a onClick={() => window.location.href = "https://www.instragram.com/dawcon.ca"} target='_blank'><i className="fa-brands fa-instagram"></i></a>
                <a onClick={() => window.location.href = "https://www.linkedin.com/company/dawcon-construction-inc/"} target='_blank'><i className="fa-brands fa-linkedin-in"></i></a>
            </div>
            <p>Copyright &copy; DAWCON All rights reserved.</p>
        </div>
    </div>
  )
}

export default Footer