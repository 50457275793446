import React, {useState, createContext} from 'react'
const Context = createContext();

function ContextProvider({children}) {
    const [showSidebar, setShowSidebar] = useState(false);

    return (
        <Context.Provider value={{showSidebar, setShowSidebar}}>
            {children}
        </Context.Provider>
    )
}

export {ContextProvider, Context};