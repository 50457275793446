import React from 'react'

const Header = () => {
  return (
    <div className="header">
        <div>
          <a href="tel: 6136009190"><i class="fa-solid fa-phone"></i>(613)-600-9190</a>
        </div>
        <div>
          <a href="mailto: info@dawcon.ca"><i class="fa-solid fa-envelope"></i>info@dawcon.ca</a>
        </div>
    </div>
  )
}

export default Header