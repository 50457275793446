import React, { useEffect } from 'react'
import ContactForm from '../components/ContactForm'
import Description from '../components/Description'
import Footer from '../components/Footer'
import ServicesPrev from '../components/ServicesPrev'
import Welcome from '../components/Welcome'

const Home = () => { 
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  
  return (
    <div className='home'>
        <Welcome />
        <Description />
        <ServicesPrev />
        <ContactForm />
        <Footer footerColor='light' />
    </div>
  )
}

export default Home